@import "minima";

/**
 * CSS style rules to modify HTML table defaults defined above
 */
table tr:nth-child(even) {
  background-color: #ffffff; }
table td {
  vertical-align: text-top; }

/**
 * modify code and highlight defaults defined above
 */
pre, code { background-color: #ffe; }
.highlighter-rouge .highlight { background: #ffe; }

/**
 * GL String and GL String Code (GLSC) syntax highlighting
 */
.language-glsc .p { color: #008080; }
.language-glsc .o { color: #d14; }
.language-glstring .o { color: #d14; }
